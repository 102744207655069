import { createContext, useState, useEffect, useContext } from "react";
import { GETUserData } from "../src/server/dynamoDB";
import { refreshTokens } from "../src/server/cognito";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
const [contextAuth, setAuth] = useState();
const userLoggedIn = contextAuth && contextAuth.email ? true:false;

  useEffect(() => {
    const fetchData = async () => {
      if (userLoggedIn) {
        const user = await GETUserData(contextAuth.email);
        if (user && user.email && user.email == contextAuth.email) {
          delete user.email
          const keys = Object.entries(user)
          setAuth((prev)=>{
            const newState = {...prev};
            for(let i =0;i<keys.length;i++){
              newState[keys[i][0]] = keys[i][1];
            }
            return newState
          })
        }
      }
    };
    fetchData();
  }, [userLoggedIn]);

  useEffect(() => {
    console.log(contextAuth);
  }, [contextAuth]);

  const setUserLoggedIn = (credits) => {
              const keys = Object.entries(credits);
          setAuth((prev) => {
            const newState = { ...prev };
            for (let i = 0; i < keys.length; i++) {
              newState[keys[i][0]] = keys[i][1];
            } 
            return newState;
          });
  };

  const setUserLoggedOut = ()=>{
    setAuth()
  }

  const refreshAccessToken = async ()=>{
    const response = await refreshTokens(contextAuth.auth.RefreshToken);
    if(response.AuthenticationResult){
          let auths = response.AuthenticationResult;
    auths.RefreshToken = contextAuth.auth.RefreshToken;

      setAuth({ ...contextAuth, auth: auths });
      return true
    }else if(response.e){
      return response.e;
    }
  }

  const contextValue = {
    contextAuth,
    setUserLoggedIn,
    setUserLoggedOut,
    userLoggedIn,
    refreshAccessToken,
  };

  return (
    <AuthContext.Provider value={contextValue} displayName="Auth Context">
      {children}
    </AuthContext.Provider>
  );
};
 
