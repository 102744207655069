import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

//! env
const REGION = "us-east-1";
const IDENTITY_POOL_ID = "us-east-1:b817ac9c-d9cf-41be-8fe3-88daee234143";
console.log(REGION + IDENTITY_POOL_ID);

export const AWSconfig = {
	region: REGION,
	credentials: fromCognitoIdentityPool({
		clientConfig: { region: REGION },
		identityPoolId: IDENTITY_POOL_ID,
		logins: {
			// Optional tokens, used for authenticated login.
		},
	}),
};
