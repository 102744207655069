import './Login.css';
import { useEffect, useState, useContext } from 'react';
import { signUp, signIn, verifyEmail, sendCode } from "../../src/server/cognito";
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../contextAPI/AuthContext';
import { CartContext } from '../../contextAPI/CartContext';
import { useNavigate } from "react-router-dom";
import { PUTUser } from '../../src/server/dynamoDB';

function Login() {
	const navigate = useNavigate();
	const [signUpFormShow, setSignUpFormShow] = useState(false);
	const [signUpEmail, setSignUpEmail] = useState();
	const [signUpPw, setSignUpPw] = useState();
  const [signUpPwConfirm, setSignUpPwConfirm] = useState()
	const [signInEmail, setSignInEmail] = useState();
    const [signInPw, setSignInPw] = useState();
	const [signInErr, setSignInErr] = useState("");
	const [signUpErr, setSignUpErr] = useState('');
	const [confirmAccErr, setConfirmAccErr] = useState("");
	const [accConfirmVisi, setAccConfirmVisi] = useState(false);
	const [signInAccConfirmVisi, setSignInAccConfirmVisi] = useState(false);
	const [code, setCode] = useState();
	const[sendCodeBtnDisable, setSendCodeBtnDisable] = useState(false);
	const [seconds, setSeconds]=useState(30);
		const { t } = useTranslation();
	const { contextAuth, setUserLoggedIn } = useContext(AuthContext);
const {contextCart}=useContext(CartContext)

function checkPwAvali(pw, pwConfirm){
  if(pw==pwConfirm){
    console.log("matched")
      if (pw.length >= 8 && /\d/.test(pw) && /[a-z]/.test(pw)) {
        return true;
      }else{
            setSignUpErr(t("login.pwNotMeetReq"));
      }
  }else{
        setSignUpErr(t("login.pwNotMatch"));

  }

}


	async function proceedSignUp() {
    if(signUpEmail&&signUpPw&&signUpPwConfirm){
      if (checkPwAvali(signUpPw, signUpPwConfirm)) {
        const response = await signUp(signUpEmail, signUpPw);
        if (response.$metadata) {
          if (response.$metadata.httpStatusCode == 200) {
              setSignUpErr("");
              setAccConfirmVisi(true);
            
          }
        } else if (response.e) {
          console.log(response.e.message);
          if (response.e.name == "UsernameExistsException") {
            setSignUpErr(t("login.userAlreadyExisted"));
          }
          if (response.e.name == "InvalidPasswordException") {
            setSignUpErr(t("login.pwNotMeetReq"));
          }
          if (response.e.name == "InvalidParameterException") {
            setSignUpErr(t("login.usernameShouldBeEmail"));
          } else {
            setSignUpErr(response.e.message);
          }
        }
      } else {
      }

    }else{
              setSignUpErr(t("login.emptyInput"));

    }

	}

	async function proceedSignIn() {
		//check input missing
    if(signInEmail&&signInPw){
		const response = await signIn(signInEmail, signInPw);
    if (response.$metadata) {
      if (response.$metadata.httpStatusCode == 200) {
        const auth = response.AuthenticationResult;
        const user = { email: signInEmail, auth };
        setSignInErr("");
        setUserLoggedIn(user);
      }
    } else if (response.e) {
      console.log(response.e.name);
      if (response.e.name == "UserNotConfirmedException") {
        setSignInAccConfirmVisi(true);
        setSignUpFormShow(false);
      } else if (response.e.name == "NotAuthorizedException") {
        setSignInErr(t("login.invalidUsernameOrPw"));
      } else {
        setSignInErr(response.e.message);
      }
    }
    }else{
      setSignInErr(t("login.emptyInput"));
    }

	}

	async function proceedVerifyEmail(email) {
		console.log(code)
      const response = await verifyEmail(email, code);
		if (response.$metadata) {
      if (response.$metadata.httpStatusCode == 200) {
			  setConfirmAccErr("");
			  setSignUpErr("")
			  setAccConfirmVisi(false)
			  setSignInAccConfirmVisi(false);
        setSignUpFormShow(false);
        await PUTUser(signUpEmail, contextCart);
      }
    } else if (response.e) {
      console.log(response.e.message);
	  if (response.e.name=="CodeMismatchException"){
	  	setConfirmAccErr(t("login.invalidCode"));
	  }else if (response.e.name == "") {
		//!
		setConfirmAccErr(t("login.expiredCode"));
    } else {
      setConfirmAccErr(response.e.message);
    }
    }
    }

	async function proceedSendCode(email){
        const response = await sendCode(email);
		setSendCodeBtnDisable(true);
        if (response.$metadata) {
          if (response.$metadata.httpStatusCode == 200) {
			setSeconds(30)
			const countdownInterval = setInterval(()=>{
				setSeconds((prev)=>prev -1)
			},1000)
			setTimeout(()=>{
				clearInterval(countdownInterval)
				setSendCodeBtnDisable(false)
			},30000)
          }
        } else if (response.e) {
			setConfirmAccErr(response.e.message);
        }
		

	}

	return (
    <div className="Login">
      <div
        className={
          signUpFormShow
            ? "container content-body right-panel-active mobile-signup-form-active"
            : "container content-body"
        }
        id="container"
      >
        <div className="form-container sign-up-container">
          <div className="form">
            <div
              className="input-form"
              style={{ display: accConfirmVisi ? "none" : "flex" }}
            >
              <h1>{t("login.signUp")}</h1>
              <div className="social-container">
                <a
                  href="https://www.google.com"
                  className="social"
                  aria-label="google login"
                >
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
              <span>{t("login.or")}</span>
              <input
                type="email"
                placeholder={t("email")}
                onChange={(e) => setSignUpEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder={t("password")}
                onChange={(e) => setSignUpPw(e.target.value)}
              />
              <input
                type="password"
                placeholder={t("confirmPassword")}
                onChange={(e) => setSignUpPwConfirm(e.target.value)}
              />
              <p className="pw-req-text">{t("login.pwReq")}:</p>
              <p className="pw-req-text">-{t("login.pwReq1")}: 8</p>
              <p className="pw-req-text">-{t("login.pwReq2")}</p>

              <div className="error-msg">{signUpErr}</div>
              <button
                className="button white-text-button"
                onClick={() => proceedSignUp()}
              >
                {t("login.signUp")}
              </button>
              <button
                className="mobile-signup-button button"
                id="mobile-signIn"
                onClick={() => setSignUpFormShow(false)}
              >
                {t("login.signIn")}
              </button>
            </div>
            <div
              className="confirm-account-form"
              style={{ display: accConfirmVisi ? "flex" : "none" }}
            >
              <div
                className="back-to-sign-up-button link"
                onClick={() => setAccConfirmVisi(false)}
              >
                {"<"} {t("login.signUp")}
              </div>
              <h1>{t("login.verifyYourEmail")}</h1>
              <p className="link">
                {t("login.verifyP1")}
                {signUpEmail}
              </p>
              <input
                placeholder={t("login.code")}
                onChange={(e) => setCode(e.target.value)}
              />
              <p className="error-msg">{confirmAccErr}</p>
              <a
                className="link"
                onClick={
                  sendCodeBtnDisable ? null : () => proceedSendCode(signUpEmail)
                }
                style={{ cursor: !sendCodeBtnDisable && "pointer" }}
              >
                {t("login.sendAgain")}
                {sendCodeBtnDisable ? "(" + seconds + ")" : null}
              </a>
              <button
                className="button white-text-button"
                onClick={() => {
                  proceedVerifyEmail(signUpEmail);
                }}
              >
                {t("login.verify")}
              </button>
            </div>
          </div>
        </div>
        <div className="form-container sign-in-container">
          <div className="form">
            <div
              style={{ display: signInAccConfirmVisi ? "none" : "flex" }}
              className="input-form"
            >
              <h1>{t("login.signIn")}</h1>
              <div className="social-container">
                <a
                  href="https://www.google.com"
                  className="social"
                  aria-label="google login"
                >
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
              <span>{t("login.or")}</span>
              <input
                type="email"
                placeholder={t("email")}
                onChange={(e) => setSignInEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder={t("password")}
                onChange={(e) => setSignInPw(e.target.value)}
              />
              <p className="error-msg">{signInErr}</p>
              <a href="#" className="link">
                {t("login.forgotPw")}
              </a>
              <button
                onClick={() => proceedSignIn()}
                className="white-text-button button"
              >
                {t("login.signIn")}
              </button>
              <button
                className="mobile-signup-button button"
                id="mobile-signUp"
                onClick={() => setSignUpFormShow(true)}
              >
                {t("login.signUp")}
              </button>
            </div>
            <div
              className="confirm-account-form"
              style={{ display: signInAccConfirmVisi ? "flex" : "none" }}
            >
              <div
                className="back-to-sign-up-button link"
                onClick={() => setSignInAccConfirmVisi(false)}
              >
                {"<"} {t("login.signIn")}
              </div>
              <h1>{t("login.verifyYourEmail")}</h1>
              <div style={{ fontSize: 13 }}>{signInEmail}</div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "5%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  placeholder={t("login.code")}
                  onChange={(e) => setCode(e.target.value)}
                  className="sign-in-code-input"
                />
                <div
                  className="sign-in-send-code-btn"
                  style={{
                    backgroundColor: sendCodeBtnDisable && "grey",
                    cursor: !sendCodeBtnDisable && "pointer",
                  }}
                  onClick={
                    sendCodeBtnDisable
                      ? null
                      : () => proceedSendCode(signInEmail)
                  }
                >
                  {t("login.sendCode")}
                  {sendCodeBtnDisable ? "(" + seconds + ")" : null}
                </div>
              </div>

              <p className="error-msg">{confirmAccErr}</p>
              <button
                className="button"
                onClick={() => {
                  proceedVerifyEmail(signInEmail);
                }}
              >
                {t("login.verify")}
              </button>
            </div>
          </div>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>{t("login.overlayP1")}</h1>
              <p>{t("login.overlayP2")}</p>
              <button
                className="ghost"
                id="signIn"
                onClick={() => setSignUpFormShow(false)}
              >
                {t("login.signIn")}
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1>{t("login.overlayP3")}</h1>
              <p>{t("login.overlayP4")}</p>
              <button
                className="ghost"
                id="signUp"
                onClick={() => setSignUpFormShow(true)}
              >
                {t("login.signUp")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
