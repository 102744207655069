import './Sorted.css';
import { useLocation, Link, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { ProductsContext } from '../../contextAPI/ProductsContext';
import { GETProductsListByCat } from '../../src/server/dynamoDB';

function Sorted() {
	const location = useLocation();
	const { cat } = useParams();
	const { contextProducts, fetchProductListToContext } =
		useContext(ProductsContext);
	const [renderProductsList, setRenderProductsList] = useState();
	useEffect(() => {
    //StrictMode will cause useEffect run twice in development mode

    console.log("useEffect,[contextProducts]");

	 if (!contextProducts || Object.entries(contextProducts).length === 0) {
     async function fetchData() {
       try {
         await fetchProductListToContext();
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     }
     fetchData();
   } else {
     setRenderProductsList(contextProducts[cat]);
   }
    /*if (!contextProducts || Object.entries(contextProducts).length === 0) {
      async function fetchData() {
        try {
          const response = await GETProductsListByCat(cat);
          setRenderProductsList(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      fetchData();
    } else {
      setRenderProductsList(contextProducts[cat]);
    }*/
  }, [contextProducts]);

	return (
		<div className="Cookies">
			<div className="title">{cat}</div>
			{(renderProductsList && (
				<div className="products-container">
					{Object.keys(renderProductsList).map((productId) => (
						<Link
							to={`${productId}`}
							className="product-container"
							key={productId}>
							<img
								src="products/01/thumbnail.jpg"
								alt="product"
								className="product-thumbnail"></img>
							<div className="label">
								{' '}
								{renderProductsList[productId].name}
							</div>
							<div className="label">
								HKD${renderProductsList[productId].price}/100g
							</div>
						</Link>
					))}
				</div>
			)) ||
				null}
		</div>
	);
}

export default Sorted;
