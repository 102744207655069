import React from 'react';
import './index.css';// import ProductDetail from './productDetail/ProductDetail';
import { ProductsProvider } from './contextAPI/ProductsContext';
import { CartProvider } from './contextAPI/CartContext';
import { AuthProvider } from './contextAPI/AuthContext';
import App2 from './App2';

const App = () => {
	return (
    <ProductsProvider>
      <AuthProvider>
        <CartProvider>
          <App2 />
        </CartProvider>
      </AuthProvider>
    </ProductsProvider>
  );
};

export default App;
