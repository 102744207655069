import './AllSorts.css';
import { useLocation, Link } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { ProductsContext } from '../../contextAPI/ProductsContext';
import { useTranslation } from 'react-i18next';

function AllSorts() {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const { contextProducts, fetchProductListToContext } =
		useContext(ProductsContext);
	const [sortedProductsList, setSortedProductsList] = useState();
	useEffect(() => {
		//StrictMode will cause useEffect run twice in development mode

		console.log('useEffect,[]');

		if (!contextProducts || Object.entries(contextProducts).length === 0) {
			async function fetchData() {
				try {
					await fetchProductListToContext();
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			}
			fetchData();
		}
	}, []);

	return (
		<div className="AllSorts">
			<div className="banner">
				<div className="left"></div>
				<div className="right">{t('banner')}</div>
			</div>
			{(contextProducts && (
				<div>
					{Object.keys(contextProducts).map((cat) => (
						<div key={cat}>
							<div className="title-container">
								<div className="title">{cat}</div>
								<Link className="button" to={`${cat}`}>
									{t('seeMore')}
									{'>>'}
								</Link>
							</div>
							<div className="products-container">
								{Object.keys(contextProducts[cat]).map((productId) => (
									<Link
										to={`${cat}/${productId}`}
										className="product-container"
										key={productId}>
										<img
											src="products/01/thumbnail.jpg"
											alt="product"
											className="product-thumbnail"></img>
										<div className="label">
											{contextProducts[cat][productId].name}
										</div>
										<div className="label">
											HKD${contextProducts[cat][productId].price}
											/100g
										</div>
									</Link>
								))}
							</div>
						</div>
					))}
				</div>
			)) ||
				null}
		</div>
	);
}

export default AllSorts;
