import React,{useContext,useEffect} from 'react';
import "./Sale.css"
import { ProductsContext } from '../../contextAPI/ProductsContext';

const Sale = () => {
		const {test} = useContext(ProductsContext);
	return <div className='sale'>{ test}sale</div>;
};

export default Sale;
