import React, { useContext } from 'react';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  GetCommand,
  QueryCommand,
  ScanCommand,
  PutCommand,
  UpdateCommand,
} from "@aws-sdk/lib-dynamodb";
import { AWSconfig } from './config';

const client = new DynamoDBClient(AWSconfig);
const docClient = DynamoDBDocumentClient.from(client);

export const GETFullProductsList = async () => {
	console.log('getting all products from database');
	const command = new ScanCommand({
		TableName: 'maoistore_products',
	});
	try {
		const response = await docClient.send(command);
		const products_awsFormat = response.Items;
		const organizedData = {};
		for (const key in products_awsFormat) {
			const item = products_awsFormat[key];

			// Check if the category already exists in organizedData, if not, create an empty array for it
			if (!organizedData[item.cat]) {
				organizedData[item.cat] = [];
			}

			// Add the object to the corresponding category array
			organizedData[item.cat][item.productId] = item;
		}
		return organizedData;
	} catch (e) {}
};

export async function GETProductsListByCat(cat) {
	console.log('getting products by category from database');
	const command = new QueryCommand({
		TableName: 'maoistore_products',
		IndexName: 'cat-index',
		KeyConditionExpression: 'cat = :cat',
		ExpressionAttributeValues: {
			':cat': cat,
		},
	});
	try {
		const response = await docClient.send(command);
		const products_awsFormat = response.Items;
		const products_transformedData = {};
		for (const item of products_awsFormat) {
			products_transformedData[item.productId] = {
				...item,
			};
		}
		return products_transformedData;
	} catch (e) {
		console.log('Error getting product list from AWS : ' + e);
	}
}

export const GETProductById = async (Id) => {
	console.log('getting product by product ID from database');

	const command = new GetCommand({
		TableName: 'maoistore_products',
		Key: {
			productId: Id,
		},
	});

	const response = await docClient.send(command);
	const product_awsFormat = response.Item;
	return product_awsFormat;
};

export const GETUserData = async (userEmail) => {
  console.log("getting user data from dynamoDB");

  const command = new GetCommand({
    TableName: "maoistore_users",
    Key: {
      email: userEmail,
    },
  });

  const response = await docClient.send(command);
  const user_awsFormat = response.Item;
  return user_awsFormat;
};

export const PUTUser = async (userEmail, cartItems) => {
  const command = new PutCommand({
    TableName: "maoistore_users",
    Item: {
      email: userEmail,
      cart: cartItems,
    },
  });
   const response = await docClient.send(command);
   console.log(response)
   return response;
};


//will overwrite old record
export const updateUserCart = async (userEmail, cartItems) => {
  const command = new UpdateCommand({
    TableName: "maoistore_users",
    Key: {
      email: userEmail,
    },
    ExpressionAttributeValues: {
      ":val": cartItems,
    },
    UpdateExpression: "SET cart=:val",
    ReturnValues: "UPDATED_NEW",
  });
  const response = await docClient.send(command);
  console.log(response);
  return response;
};