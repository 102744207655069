import React from 'react';
import { useTranslation } from "react-i18next";

const Lang = () => {
const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

	return (
    <div>
      <div>{t("lang")}</div>
      <button type="button" onClick={() => changeLanguage("zh")}>
        繁
      </button>
      <button type="button" onClick={() => changeLanguage("en")}>
        EN
      </button>
    </div>
  );
};

export default Lang;
