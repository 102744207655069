import React, { useContext, useEffect, useState } from "react";
import "./Profile.css";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contextAPI/AuthContext";
import { CartContext } from "../../contextAPI/CartContext";
import { useNavigate } from "react-router-dom";
import { signOut, changePw } from "../../src/server/cognito";
import Login from "./Login";
import {updateUserCart} from "../../src/server/dynamoDB"

const Profile = () => {
  const { t } = useTranslation();
  const {
    userLoggedIn,
    contextAuth,
    setUserLoggedOut,
    refreshAccessToken,
  } = useContext(AuthContext);
    const { contextCart, whileSignOut } = useContext(CartContext);
  const [oldPw,setOldPw]= useState()
  const [newPw, setNewPw] = useState()

  const proceedSignOut=async()=>{
    await updateUserCart(contextAuth.email, contextCart);
    const response = await signOut(contextAuth.auth.AccessToken);
   if (response.$metadata && response.$metadata.httpStatusCode === 200) {
     setUserLoggedOut();
     whileSignOut();
   }else if(response.e){
    console.log(response.e.name);
    if (response.e.name == "NotAuthorizedException") {
      const response2 = await refreshAccessToken(contextAuth.auth.RefreshToken);
      if (response2.$metadata && response2.$metadata.httpStatusCode === 200) {
        proceedSignOut();
      } else {
        if(response2.e){
        alert(response2.e.message);

        }
        return;
      }
    } else {
      alert(response.e.message+". please contact us.");
    }

   }
  }
  const proceedChangePw=async()=>{
    const response = await changePw(contextAuth.token, oldPw, newPw);
    if (response.$metadata && response.$metadata.httpStatusCode === 200) {
      alert("Password changed.");
    } else if (response.e) {
      alert(response.e.message);
    }
  }

 // const paymentMethods = contextAuth&&contextAuth.paymentMethods?contextAuth.paymentMethods:null
const paymentMethods = ()=>{
  if(contextAuth&&contextAuth.paymentMethods){
    let splitedPaymentMethods = contextAuth.paymentMethods.map(pm=>({...pm}));
  for(let i =0; i<contextAuth.paymentMethods.length;i++){    
    const details = contextAuth.paymentMethods[i].details;
    const splitedDetails = details.split("#")
    splitedPaymentMethods[i].details = splitedDetails;
  }
  return splitedPaymentMethods;

  }
  else return null
}

  return (
    <div className="Profile">
      {userLoggedIn && (
        <div>
          <div className="column">{contextAuth.email}</div>
          <div className="column">
            <div onClick={() => proceedChangePw()} className="">
              Change Password
            </div>
            <input
              type="password"
              placeholder={t("profile.oldPassword")}
              onChange={(e) => setOldPw(e.target.value)}
              className=""
            />
            <input
              type="password"
              placeholder={t("profile.newPassword")}
              onChange={(e) => setNewPw(e.target.value)}
              className=""
            />
            <div onClick={() => proceedChangePw()} className="button">
              confirm
            </div>
          </div>
          <div className="column">
            <div className="">Address Setting</div>
            <div className="">{contextAuth.address}</div>
          </div>
          <div className="column">
            <div className="" >
              Payment Methods
            </div>
            {paymentMethods() &&
              paymentMethods().map((item, index) => (
                <div key={index} style={{ display: "flex" }}>
                  <div className="payment-method-item">{item.type}</div>
                  {item.details.map((detail, i) => (
                    <div className="payment-method-item" key={i}>
                      {detail}
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <div className="column">
            <div>Order History</div>
            <div className="">{contextAuth.orderIds}</div>
          </div>
          <div className="column">Coupon</div>
          <div
            onClick={() => proceedSignOut()}
            className="column button"
            style={{ color: "red" }}
          >
            Sign Out
          </div>
        </div>
      )}
      {!userLoggedIn && <Login />}
    </div>
  );
};

export default Profile;
