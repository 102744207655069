import React, { useContext, useEffect } from 'react';
import "./New.css"
import { ProductsContext } from '../../contextAPI/ProductsContext';

const New = () => {
	// const {contextProducts} = useContext(ProductsContext);
	// useEffect(()=>{

	// 	console.log("contextProducts: ", contextProducts)
	// })

	return <div className="new">New</div>;
};

export default New;
