import React, { useState, useContext, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ProductsContext } from '../../contextAPI/ProductsContext';
import { CartContext } from '../../contextAPI/CartContext';
import { GETProductById } from '../../src/server/dynamoDB';
import  domain  from '../../utils/constant';
import './ProductDetail.css';

const Product = () => {
	const { cat, productId } = useParams();
	const { contextProducts, fetchProductListToContext } =
    useContext(ProductsContext);
	const { contextCart, addToCart } = useContext(CartContext);

	const [renderProduct, setRenderProduct] = useState();

	useEffect(() => {
    //StrictMode will cause useEffect run twice in development mode

    console.log("useEffect,[contextProducts]");
    if (!contextProducts || Object.entries(contextProducts).length === 0) {
      async function fetchData() {
        try {
          await fetchProductListToContext();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      fetchData();
    } else {
      setRenderProduct(contextProducts[cat][productId]);
    }

    /*if (!contextProducts || Object.entries(contextProducts).length === 0) {
			async function fetchData() {
				try {
					const product = await GETProductById(productId);
					setRenderProduct(product);
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			}
			fetchData();
		} else {
			setRenderProduct(contextProducts[cat][productId]);
		}*/
  }, [contextProducts]);

	const [qty, setQty] = useState(1);

	return (
    <div className="productDetail">
      <div className="path-name-container">
        <Link to={`${domain}`} className="path-name path">
          all
        </Link>
        <a className="path-name"> / </a>
        <Link to={`${domain}/${cat}`} className="path-name path">
          {cat}
        </Link>
        <a className="path-name"> / </a>
        {renderProduct && <a className="path-name">{renderProduct.name}</a>}
      </div>
      {renderProduct && (
        <div className="product-detail-container">
          <img
            src="/products/01/thumbnail.jpg"
            alt="product"
            className="product-photos"
          ></img>
          <div className="product-description-container">
            <div className="product-title">{renderProduct.name}</div>
            <div className="product-id">{renderProduct.productId}</div>
            <div className="product-price">${renderProduct.price}/100g</div>
            {/* <div className="product-description">Ingredients: {renderProduct.ingredients.map((i) => <div className="ingredient-item">{i}</div>)}
					</div> */}
            <div className="qty-selector">
              <div
                className="btn"
                onClick={() => {
                  if (qty > 1) {
                    setQty(qty - 1);
                  }
                }}
              >
                <div className="text">-</div>
              </div>
              <div className="qty">
                <div>{qty}</div>
              </div>
              <div className="btn" onClick={() => setQty(qty + 1)}>
                <div className="text">+</div>
              </div>
            </div>
            <div
              className="add-to-cart-btn"
              onClick={() => addToCart(productId, qty)}
            >
              Add To Cart
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
