import { createContext, useContext, useState } from 'react';
import { GETProductsListByCat, GETFullProductsList } from '../src/server/dynamoDB';

export const ProductsContext = createContext(null);


export const ProductsProvider = ({ children }) => {
	const [contextProducts, setProducts] = useState({});

	const fetchProductListToContext = async () => {
		const productList = await GETFullProductsList();
		setProducts(productList);
	};

	const contextValue = { contextProducts, fetchProductListToContext };

	return (
		<ProductsContext.Provider
			value={contextValue}
			displayName="Products Context">
			{children}
		</ProductsContext.Provider>
	);
};
