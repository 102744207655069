import {
  CognitoIdentityProviderClient,
  SignUpCommand,
  InitiateAuthCommand,
  ConfirmSignUpCommand,
  ResendConfirmationCodeCommand,
  GlobalSignOutCommand,
  ChangePasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { AWSconfig } from './config';
import { PUTUser } from "./dynamoDB";
import { useContext } from "react";

const clientId = "2m6t6po1oelqksv8boj5rad1ur";

const client = new CognitoIdentityProviderClient(AWSconfig);

//included send code
export const signUp = async (email, password) => {
	console.log('signup');
	const command = new SignUpCommand({
		ClientId: clientId,
		Username: email,
		Password: password,
	});
	try {
		const response = await client.send(command);
		console.log(response);
		return response;
	} catch (e) {
		console.log('error signing up: ', e);
		const err = { e: e };
		return err;
	}
};

export const signIn = async (email, password) => {
	console.log('signin');
	const command = new InitiateAuthCommand({
		ClientId: clientId,
		AuthFlow: 'USER_PASSWORD_AUTH',
		AuthParameters: {
			PASSWORD: password,
			USERNAME: email,
		},
	});
	try {
		const response = await client.send(command);
		console.log(response);
		return response;
	} catch (e) {
		console.log("error signing in: ", e);

		const err = { e: e };
    return err;
	}
};

export const verifyEmail = async(email,code)=>{
	console.log("verify email");
	const command = new ConfirmSignUpCommand({
    ClientId: clientId,
	Username:email,
    ConfirmationCode:code,
  });
  	try {
      const response = await client.send(command);
      console.log(response);
      return response;
    } catch (e) {
	console.log("error verifying email: ", e);
		const err = { e: e };
    return err;
    }
}

export const sendCode = async(email)=>{
	console.log("sending code")
	const command = new ResendConfirmationCodeCommand({
		ClientId : clientId,
		Username: email
	});
	  	try {
        const response = await client.send(command);
        console.log(response);
        return response;
      } catch (e) {
        console.log("error sending code: ", e);
        const err = { e: e };
        return err;
      }
}

export const signOut = async (token)=>{
		console.log("signing out");
	const command = new GlobalSignOutCommand({
    AccessToken: token,
  });
	  	try {
        const response = await client.send(command);
        console.log(response);
        return response;
      } catch (e) {
        console.log("error signing out: ", e.message);
        const err = { e: e };
        return err;
      }
}

export const changePw = async (token, oldPw,newPw)=>{
	console.log("changing pw")
	const command = new ChangePasswordCommand({
    AccessToken: token,
    PreviousPassword: oldPw,
    ProposedPassword:newPw,
  });

  	  	try {
          const response = await client.send(command);
          console.log(response);
          return response;
        } catch (e) {
          console.log("error changing pw: ", e.message);
          const err = { e: e };
          return err;
        }

}

//call function in auth context, not this
export const refreshTokens = async (refreshToken)=>{
const command = new InitiateAuthCommand({
  ClientId: clientId,
  AuthFlow: "REFRESH_TOKEN_AUTH",
  AuthParameters: {
    REFRESH_TOKEN: refreshToken,
  },
});
try {
  const response = await client.send(command);
  console.log(response);
  return response;
} catch (e) {
  console.log("error refreshing tok: ", e);

  const err = { e: e };
  return err;
}
}