import React, { useState, useContext, useEffect } from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Terms from './pages/footer/terms/Terms';
import AboutUs from './pages/footer/aboutUs/AboutUs';
import New from './pages/new/New';
import Sale from './pages/sale/Sale';
import Lang from './pages/lang/Lang';
import Cart from './pages/cart/Cart';
import Profile from './pages/user/Profile';
import Entry from './pages/Entry';
import Sorted from './pages/sorted/Sorted';
// import ProductDetail from './productDetail/ProductDetail';
import Product from './pages/productDetail/Product';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CartContext } from './contextAPI/CartContext';
import { AuthContext } from './contextAPI/AuthContext';
import './App2.css';

const App2 = () => {
	const { contextCart } = useContext(CartContext);
	const { userLoggedIn,contextAuth, setUserLoggedIn } = useContext(AuthContext);

	const authedUser = localStorage.getItem("authedUser");

	//test
	if (process.env.NODE_ENV === "production") {
	console.log = function () {};
	}

	useEffect(() => {
		//save cart list to local storage if user not logged in
		if (contextCart && !userLoggedIn) {
      localStorage.setItem("cartList", JSON.stringify(contextCart));
    }
	}, [contextCart]);

		useEffect(() => {
      //save logged in credits to local storage
      if (userLoggedIn) {
        localStorage.setItem("authedUser", JSON.stringify(contextAuth));
      }
    }, [contextAuth]);

	useEffect(() => {
		//check if login credits saved
	const user = JSON.parse(authedUser);
      if (user && user.email) {
		console.log(user)
        setUserLoggedIn(user);
      }
	}, []);

	return (
		<BrowserRouter>
			<div className="app">
				<Header />
				<Routes>
					<Route path="/" element={<Entry />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/aboutus" element={<AboutUs />} />
					<Route path="/:cat" element={<Sorted />} />
					<Route path="/:cat/:productId" element={<Product />} />
					<Route path="/sale" element={<Sale />} />
					<Route path="/new" element={<New />} />
					<Route path="/lang" element={<Lang />} />
					<Route path="/cart" element={<Cart />} />
					<Route path="/user" element={<Profile />} />
				</Routes>
			</div>
			<Footer />
		</BrowserRouter>
	);
};

export default App2;
