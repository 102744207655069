import './Header.css';
import { useState, useEffect, useRef, useContext } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import domain from '../../utils/constant';
import '../../utils/constants.css';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../contextAPI/CartContext';

// import Logo from '../public/logo512.png';
function Header() {
	const { t, i18n } = useTranslation();
	const { contextCart } = useContext(CartContext);
	const [cartCount, setCartCount] = useState(
0
  );
	const [menuShow, setMenuShow] = useState(false);
	const [currentPath, setCurrentPath] = useState(window.location.pathname);
	const logoRef = useRef(null);

	function getHeaderCSSHeight() {
		const value = getComputedStyle(document.documentElement)
			.getPropertyValue('--headerHeight')
			.trim();
		const numericValue = parseFloat(value);

		return numericValue || 0;
	}
	useEffect(() => {
		const viewportHeight = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight || 0
		);
		//console.log((viewportHeight * getHeaderCSSHeight()) / 100);
		// 	const logo = logoRef.current;
		// 	const logoNaturalWidth = logo.naturalHeight;
		// 	console.log(logoNaturalWidth);
		// const banner = document.getElementById('banner');
		// if (banner) {
		// 	banner.style.marginTop = `-${circleDisplayedWidth / 2}px`;
		// }
	}, []);

	useEffect(() => {
		if (typeof contextCart === "object"){
      setCartCount(Object.entries(contextCart).length);

		}
  }, [contextCart]);

	return (
		<header>
			<div className="row1-container">
				<Link
					to={`${domain}`}
					onClick={() => {
						setCurrentPath('/');
					}}
					className="logo-container">
					<img
						ref={logoRef}
						src="/logo.png"
						alt="logo"
						className="logo"></img>
				</Link>
				<div className="nav-btn-container">
					<Link
						onClick={() => {
							setCurrentPath('/');
						}}
						to={`/lang`}
						className="nav-btn">
						<img
							src="/icons/lang.png"
							className="circle-button lang"
							id="nav-lang"
							alt="language"></img>
					</Link>
					<Link
						to={`/cart`}
						className="nav-btn"
						onClick={() => {
							setCurrentPath('/');
						}}>
						<img
							src="/icons/cart.png"
							className="circle-button cart"
							id="nav-cart"
							alt="carts"></img>
						<div class="badge badge-warning" id="CartCount">
							{cartCount}
						</div>
					</Link>
					<Link
						to={`/user`}
						className="nav-btn"
						onClick={() => {
							setCurrentPath('/');
						}}>
						<img
							src="/icons/acc.png"
							className="circle-button acc"
							id="nav-user"
							alt="account"></img>
					</Link>
				</div>
			</div>
			<div className="row2-container">
				<Link
					className={
						currentPath === '/cookie'
							? 'nav-link first current-page'
							: 'nav-link first'
					}
					// href={`/cookies`}
					id="nav-cookies"
					to="/cookie"
					onClick={() => {
						setCurrentPath('/cookie');
					}}>
					Cookie
				</Link>
				<Link
					className={
						currentPath === '/new' ? 'nav-link current-page' : 'nav-link'
					}
					// href={`/new`}
					id="nav-new"
					to="/new"
					onClick={() => {
						setCurrentPath('/new');
					}}>
					{t('header.new')}
				</Link>
				<Link
					className={
						currentPath === '/sale' ? 'nav-link current-page' : 'nav-link'
					}
					// href={`/sale`}
					id="nav-sale"
					to="/sale"
					onClick={() => {
						setCurrentPath('/sale');
					}}>
					{t('header.sale')}
				</Link>
				<i
					className="fa-solid fa-bars bar"
					onClick={() => {
						setMenuShow(true);
					}}></i>
			</div>
			<div
				className={
					menuShow
						? 'mobile-row2-container menuShown'
						: 'mobile-row2-container'
				}>
				<i
					className="fa-solid fa-xmark close"
					onClick={() => {
						setMenuShow(false);
					}}></i>
				<Link
					className={
						currentPath === '/cookie'
							? 'nav-link first current-page'
							: 'nav-link first'
					}
					// href={`/cookies`}
					id="nav-cookies"
					to="/cookie"
					onClick={() => {
						setCurrentPath('/cookie');
					}}>
					Cookie
				</Link>
				<Link
					className={
						currentPath === '/new' ? 'nav-link current-page' : 'nav-link'
					}
					// href={`/new`}
					id="nav-new"
					to="/new"
					onClick={() => {
						setCurrentPath('/new');
					}}>
					{t('header.new')}
				</Link>
				<Link
					className={
						currentPath === '/sale' ? 'nav-link current-page' : 'nav-link'
					}
					// href={`/sale`}
					id="nav-sale"
					to="/sale"
					onClick={() => {
						setCurrentPath('/sale');
					}}>
					{t('header.sale')}
				</Link>
			</div>
		</header>
	);
}

export default Header;
