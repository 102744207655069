import React, { useContext, useEffect, useState } from 'react';
import './Cart.css';
import { CartContext } from '../../contextAPI/CartContext';
import { ProductsContext } from '../../contextAPI/ProductsContext';


const Cart = () => {
	const { contextProducts } = useContext(ProductsContext);
	const { contextCart, removeFromCart, addToCart, updateDynamoDBCart } =
    useContext(CartContext);
	const [renderCartList, setRenderCartList] = useState();
	const [renderCartDesList, setRenderCartDesList] = useState();

	useEffect(() => {
		//filter contextCart, remove items that have 0 quantity
		// if (contextCart && Object.entries(contextCart).length !== 0) {
		// 	const productIdList = Object.entries(contextCart);
		// 	const list = {};
		// 	for (let i = 0; i < productIdList.length; i++) {
		// 		const productId = productIdList[i][0];
		// 		if (contextCart[productId] !== 0) {
		// 			list[productId] = contextCart[productId];
		// 		}
		// 	}
		// 	setRenderCartList(list);
		// } else {
		setRenderCartList(contextCart);
		// }
	}, [contextCart]);

	useEffect(() => {
		//get cart item details
		let renderDesList = {};
		if (renderCartList) {
			const cartIdList = Object.keys(renderCartList);
			const catList = Object.keys(contextProducts);
			for (let i = 0; i < catList.length; i++) {
				const sortedProductList = contextProducts[catList[i]];
				const filteredProductsList = cartIdList.reduce((result, key) => {
					if (sortedProductList.hasOwnProperty(key)) {
						result[key] = sortedProductList[key];
					}
					return result;
				}, {});
				renderDesList = Object.assign(filteredProductsList, renderDesList);
				setRenderCartDesList(filteredProductsList);
			}
		}
	}, [renderCartList]);

	return (
		<div className="cartPage">
			<div className="title">Shopping Cart</div>
			{renderCartList && renderCartDesList && (
				<div className="cart-container">
					{Object.keys(renderCartList).map((productId) => (
						<div className="cart-item-container" key={productId}>
							<div className="cart-item-box">
								<img
									src="/products/01/thumbnail.jpg"
									alt="product"
									className="product-photo"></img>
							</div>
							<div className="cart-item-box product-id">
								<div className="product-id-text">
									{renderCartDesList[productId].name}
									{productId}
									{renderCartDesList[productId].des}
								</div>
							</div>
							<div className="cart-item-box">
								<div className="qty-selector">
									<div
										className="btn"
										onClick={() => {
											removeFromCart(productId);
											updateDynamoDBCart();
										}}>
										<div className="text">-</div>
									</div>
									<div className="qty">
										<div>{contextCart[productId]}</div>
									</div>
									<div
										className="btn"
										onClick={() => {
											addToCart(productId, 1);
											updateDynamoDBCart();
										}}>
										<div className="text">+</div>
									</div>
								</div>
							</div>
							<div className="cart-item-box">
								<div>{renderCartDesList[productId].price}</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Cart;
