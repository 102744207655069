import { createContext, useState, useContext, useEffect } from 'react';
import { ProductsContext } from './ProductsContext';
import { AuthContext } from "./AuthContext";
import { updateUserCart } from '../src/server/dynamoDB';

export const CartContext = createContext(null);

export const CartProvider = ({ children }) => {
	const { contextProducts } = useContext(ProductsContext);
	const { userLoggedIn, contextAuth } = useContext(AuthContext);
	const [contextCart, setCart] = useState({}); //get local storage cart list in App2.js

	const cartList = localStorage.getItem("cartList");


    const updateDynamoDBCart = async () => {
      if (userLoggedIn) {
        console.log("addCartToUserDynamoDB");
        const response = await updateUserCart(contextAuth.email, contextCart);
        console.log(response);
      }
    };

	//!check if there are unavailable products in cart
	const checkCart = async () => {
		let cart = {};
		//!
		console.log('Checking cart', cart);
	};

	useEffect(() => {
    console.log("useEffect,[userLoggedIn]");
	console.log(userLoggedIn);
	let localStorageCart;
	if(cartList){
		localStorageCart = JSON.parse(cartList);
	}
    const initializeCart = async () => {
      if (localStorageCart && !userLoggedIn) {
        if (Object.keys(cartList).length !== 0) {
          setCart(JSON.parse(cartList));
        }
      } else if (userLoggedIn && !localStorageCart) {
        setCart(contextAuth.cart);
      } else if (
        userLoggedIn &&
        localStorageCart &&
        Object.entries(localStorageCart).length > 0
      ) {
        const combinedList = Object.assign(localStorageCart, contextAuth.cart);
        setCart(combinedList);
        localStorage.removeItem("cartList");
      }

    };

    initializeCart();
  }, [userLoggedIn]);

  useEffect(()=>{
	if (userLoggedIn){
setCart(contextAuth.cart);
	} 
  },[contextAuth])


  
	useEffect(() => {
    console.log("useEffect,[contextProducts]");
        if (contextProducts) {
          if (Object.keys(contextProducts).length !== 0) {
            checkCart();
          }
        }
  }, [contextProducts]);


	useEffect(()=>{

console.log(contextCart);
	},[contextCart])

	const addToCart = (productId, qty) => {
		console.log('add to cart :', productId, ', qty : ', qty);
		if(contextCart){
		let cart = JSON.parse(JSON.stringify(contextCart));
    if (contextCart[productId]) {
      cart[productId] = cart[productId] + qty;
      //setCart((prev) => ({ ...prev, [productId]: prev[productId] + qty }));
    } else {
      //setCart((prev) => ({
      //	...prev,
      //	[productId]: qty,
      //	}));
      cart[productId] = qty;
    }
    console.log(cart);
    setCart(cart);
		}

	};

	const removeFromCart = (productId) => {
		if (contextCart[productId] - 1 == 0) {
			const updatedState = { ...contextCart };
			delete updatedState[productId];
			setCart(updatedState);
		} else {
			setCart((prev) => ({
				...prev,
				[productId]: prev[productId] - 1,
			}));
		}
	};

	const whileSignOut = ()=>{
		localStorage.removeItem("cartList");
setCart({})
	}


	const contextValue = {
    contextCart,
    addToCart,
    removeFromCart,
    setCart,
    updateDynamoDBCart,
	whileSignOut
  };

	return (
		<CartContext.Provider value={contextValue} displayName="Cart Context">
			{children}
		</CartContext.Provider>
	);
};
