import { Link } from 'react-router-dom';
import './Footer.css';
import domain from '../../utils/constant';
import { useTranslation } from 'react-i18next';

function Footer() {
	const version = '1.4.2';
	const { t } = useTranslation();
	return (
		<footer>
			<div className="contact-us-container">
				<p className="label">{t('footer.contactUs')}:</p>
				<div className="item-container">
					<i className="fa-brands fa-whatsapp"></i>
					<p className="label">+852-</p>
				</div>
				<div className="item-container">
					<i className="fa-regular fa-at"></i>
					<p className="label">maoistore@gmail.com</p>
				</div>
				<div className="item-container">
					<i className="fa-brands fa-instagram"></i>
					<p className="label">maoistore_</p>
				</div>
			</div>
			<div className="menu-container">
				<Link className="label" to={`${domain}/aboutus`}>
					{t('footer.aboutUs')}
				</Link>
				<Link className="label" to={`${domain}/terms`}>
					{t('footer.terms')}
				</Link>
			</div>
			<p className="label">maoistore.com @2023 V{version}</p>
		</footer>
	);
}

export default Footer;
