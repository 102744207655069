import React from 'react';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
	const { t } = useTranslation();

	return <div className="about-us">{t('footer.aboutUs')}</div>;
};

export default AboutUs;
